import { Injectable, Injector } from '@angular/core'
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http'
import { Observable, of, timer } from 'rxjs'
import { switchMap } from 'rxjs/operators'

const fakeJwtToken =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IllhbmcgUXVpbm4iLCJpYXQiOjE1MTYyMzkwMjJ9.wUC8EHj5nw3BxMolRm3ymR6PvGeyjAiP4V0nyE8NVlU'
const users = [
  {
    id: 1,
    email: 'demo@rhthermo.com',
    password: 'demo123',
    name: 'Yang Quinn',
    avatar: '',
    role: 'admin',
    accessToken: fakeJwtToken,
  },
]

@Injectable()
export class MockHttpCallInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.method === 'POST') {
      // login
      if (request.url === '/api/auth/login') {
        const { email, password } = request.body
        const user: any = users.find(item => item.email === email && item.password === password)
        const error = user ? '出错了。' : '登录失败，请重新登录'

        if (user) {
          return timer(500).pipe(switchMap(() => of(new HttpResponse({ status: 200, body: user }))))
        }

        return of(new HttpResponse({ status: 401, body: error }))
      }

      // register
      if (request.url === '/api/auth/register') {
        const { email, password, name } = request.body
        const user = users.find(user => user.email === email)

        if (!user) {
          const user = {
            id: users.length + 1,
            email,
            password,
            name,
            avatar: '',
            role: 'admin',
            accessToken: fakeJwtToken,
          }
          users.push(user)

          return of(new HttpResponse({ status: 200, body: user }))
        }

        return of(new HttpResponse({ status: 401, body: '该邮箱已注册' }))
      }
    }

    if (request.method === 'GET') {
      // load account
      if (request.url === '/api/auth/account') {
        const AccessToken = request.headers.get('AccessToken')
        const user: any = users.find(user => user.accessToken === AccessToken)

        if (user) {
          return timer(500).pipe(switchMap(() => of(new HttpResponse({ status: 200, body: user }))))
        }

        return of(new HttpResponse({ status: 401 }))
      }

      // logout
      if (request.url === '/api/auth/logout') {
        return of(new HttpResponse({ status: 200 }))
      }
    }

    return next.handle(request)
  }
}
