import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { DashboardComponent } from '@app/pages/dashboard/dashboard.component'
import { ProjectDashboardComponent } from '@app/pages/dashboard/project-dashboard/project-dashboard.component'
let routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    data: { title: '区域总控制台' },
  },
  {
    path: 'region/shanxi/1',
    component: ProjectDashboardComponent,
    data: { title: '山西 - 太原学院', id: 'shanxi-1' },
  },
  {
    path: 'region/shanxi/2',
    component: ProjectDashboardComponent,
    data: { title: '山西 - 山西同煤大厦', id: 'shanxi-2' },
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DashboardRoutingModule {}
