import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-unity',
  templateUrl: './unity.component.html',
  styleUrls: ['./unity.component.scss'],
})
export class UnityComponent implements OnInit {
  unityInstance: any

  constructor() {}

  ngOnInit(): void {
    const loader = (window as any).UnityLoader
    this.unityInstance = loader.instantiate(
      'unityContainer',
      '../../../assets/unity-webgl/Build/RHeatTest.json',
    )
  }
}
