<div class="chart-box-wrapper">
  <div class="chart-box-head-wrapper">
    <div class="head-icon"></div>
    <div class="head-title">{{ title }}</div>
  </div>
  <div class="separate-wrapper">
    <div class="separate"></div>
  </div>
  <div class="content-wrapper">
    <ng-content></ng-content>
  </div>
  <div class="separate-wrapper">
    <div class="separate"></div>
  </div>
</div>
