<div [ngClass]="{
  'ui__layout__grayBackground': isGrayBackground
}">
  <nz-layout [ngClass]="{
  'ui__layout__contentMaxWidth': isContentMaxWidth,
  'ui__layout__appMaxWidth': isAppMaxWidth,
  'ui__layout__grayBackground': isGrayBackground,
  'ui__layout__squaredBorders': isSquaredBorders,
  'ui__layout__cardsShadow': isCardShadow,
  'ui__layout__borderless': isBorderless
}">

    <!-- left menu -->
    <!-- FIX: nz-sider wrapper fix layout behaviour in case when app-menu-left root tag breaks layout transformation -->
    <nz-sider *ngIf="menuLayoutType === 'left' && !isMobileView" [nzWidth]="isMenuCollapsed ? 80 : leftMenuWidth"
      style="transition: none !important">
      <app-menu-left></app-menu-left>
    </nz-sider>

    <!-- mobile menu with drawer -->
    <div *ngIf="isMobileView">
      <div class="handler" (click)="toggleMobileMenu()">
        <div class="handlerIcon"></div>
      </div>
      <nz-drawer [nzClosable]="false" [nzWrapClassName]="'mobileMenu'" [nzVisible]="isMobileMenuOpen" nzPlacement="left"
        (nzOnClose)="toggleMobileMenu()">
        <app-menu-left></app-menu-left>
      </nz-drawer>
    </div>

    <!-- top menu -->
    <div *ngIf="menuLayoutType === 'top' && !isMobileView">
      <app-menu-top></app-menu-top>
    </div>

    <nz-layout>
      <nz-header [ngClass]="{
        'ui__layout__header': true,
        'ui__layout__fixedHeader': isTopbarFixed,
        'ui__layout__headerGray': isGrayTopbar
      }">
        <app-topbar></app-topbar>
      </nz-header>
      <nz-content style="height: 100%; position: relative;">
        <div class="ui__utils__content">
          <div [@slideFadeinUp]="routeAnimation(outlet, 'slideFadeinUp')"
            [@slideFadeinRight]="routeAnimation(outlet, 'slideFadeinRight')"
            [@zoomFadein]="routeAnimation(outlet, 'zoomFadein')" [@fadein]="routeAnimation(outlet, 'fadein')">
            <router-outlet #outlet="outlet"></router-outlet>
          </div>
        </div>
      </nz-content>
      <nz-footer>
        <app-footer></app-footer>
      </nz-footer>
    </nz-layout>
  </nz-layout>
</div>
