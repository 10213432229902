<div fxLayout="column">
  <div fxFlex="75" fxLayout="row">
    <nz-card fxFlex="auto">
      <app-map fxFlex="auto"></app-map>
    </nz-card>
  </div>
  <div fxFlex="25" fxLayout="row" fxLayout.lt-md="column">
    <nz-card fxFlex="35" fxLayoutAlign="center center">
      <app-weather fxFlex="auto"></app-weather>
    </nz-card>
    <nz-card fxFlex="65" fxLayout="row" [nzBodyStyle]="{ width: '100%', display: 'flex' }">
      <app-list fxFlex="auto"></app-list>
    </nz-card>
  </div>
</div>
