<div class="data-bottom-wrapper">
  <div class="data-bottom-bg-wrapper">
    <div class="data-bottom-title-wrapper">
      <div class="data-bottom-title">{{ title }}</div>
    </div>
    <div class="data-bottom-separate-wrapper">
      <div class="data-bottom-separate"></div>
    </div>
    <div class="data-bottom-content-wrapper">
      <div *ngFor="let item of data" class="data-bottom-content">
        <div class="data-bottom-content-title">{{ item.name }}</div>
        <div class="data-bottom-content-number">{{ item.val }}</div>
      </div>
    </div>
  </div>
</div>
