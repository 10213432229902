import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { NgModule, LOCALE_ID } from '@angular/core'
import { TranslateModule } from '@ngx-translate/core'
import { FormsModule } from '@angular/forms'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { JwtModule } from '@auth0/angular-jwt'

import { NgProgressModule } from '@ngx-progressbar/core'
import { NgProgressRouterModule } from '@ngx-progressbar/router'
import { NgProgressHttpModule } from '@ngx-progressbar/http'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { StoreModule } from '@ngrx/store'
import { EffectsModule } from '@ngrx/effects'
import { StoreRouterConnectingModule } from '@ngrx/router-store'
import store from 'store'
import { reducers, metaReducers } from './store/reducers'
import { UserEffects } from './store/user/effects'
import { jwtAuthService } from './services/jwt'
import { MockHttpCallInterceptor } from './services/fakeApi'
import { AntdModule } from 'src/app/antd.module'
// locale registration
import { registerLocaleData } from '@angular/common'
import { default as localeEn } from '@angular/common/locales/en'

import { NZ_I18N, en_US as localeZorro } from 'ng-zorro-antd'
import { DashboardModule } from '@app/pages/dashboard/dashboard.module'
import { ComponentsModule } from '@components/components.module'
import { FlexLayoutModule } from '@angular/flex-layout'
import { NgxEchartsModule } from 'ngx-echarts'
import { DataHomeComponent } from './pages/data-home/data-home.component'
import { LoginPageComponent } from './pages/login-page/login-page.component'

const LOCALE_PROVIDERS = [
  { provide: LOCALE_ID, useValue: 'en' },
  { provide: NZ_I18N, useValue: localeZorro },
]
registerLocaleData(localeEn, 'en')

export function tokenGetter() {
  return store.get('accessToken')
}

@NgModule({
  declarations: [AppComponent, DataHomeComponent, LoginPageComponent],
  imports: [
    HttpClientModule,
    AntdModule,
    BrowserModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    FormsModule,
    AppRoutingModule,
    DashboardModule,
    ComponentsModule,

    // translate
    TranslateModule.forRoot(),

    // ngrx
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot([UserEffects]),
    StoreRouterConnectingModule.forRoot(),

    // nprogress
    NgProgressModule.withConfig({
      thick: true,
      spinner: false,
      color: '#0190fe',
    }),
    NgProgressRouterModule,
    NgProgressHttpModule,

    NgxEchartsModule,
    // JWT
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: ['api.rhthermo.com'],
      },
    }),
  ],
  providers: [
    // auth service
    jwtAuthService,

    // fake http interceptors
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MockHttpCallInterceptor,
      multi: true,
    },

    // locale providers
    ...LOCALE_PROVIDERS,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
