import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import { JwtHelperService } from '@auth0/angular-jwt'
import store from 'store'
import { environment } from '../../../environments/environment'
import { AUTH_ENDPOINT_SIGNUP_URL } from '../../../environments/config'

@Injectable()
export class jwtAuthService {
  private loginUrl = environment.authUrl
  private signupUrl = AUTH_ENDPOINT_SIGNUP_URL
  private helper = new JwtHelperService()

  constructor(private http: HttpClient) {}

  setToken(token) {
    store.set('accessToken', token)
  }

  login(): Observable<any> {
    if (!this.authenticated()) {
      store.remove('accessToken')
      window.location.href = this.loginUrl
      return of(null)
    } else {
      return of({ accessToken: store.get('accessToken') })
    }
  }

  register(): Observable<any> {
    if (!this.authenticated()) {
      store.remove('accessToken')
      window.open(this.signupUrl, '_blank')
      return of(null)
    } else {
      return of({ accessToken: store.get('accessToken') })
    }
  }

  currentAccount(): Observable<any> {
    const token = store.get('accessToken')
    return of(this.helper.decodeToken(token))
  }

  public authenticated(): boolean {
    const token = store.get('accessToken')
    try {
      this.helper.decodeToken(token)
    } catch {
      store.remove('accessToken')
      return false
    }
    return !this.helper.isTokenExpired(token)
  }

  logout(): Observable<any> {
    store.remove('accessToken')
    return of(null)
  }
}
