<div class="standard-container">
  <p class="qingkuang">
    <span>节能达标完成情况</span>
    <span
      *ngFor="let item of standlist"
      (click)="getstand(item.name)"
      [ngStyle]="{ property: standcolor }"
    >
      {{ item.name }}
    </span>
  </p>
  <div echarts [options]="standOption" [autoResize]="true" style="height: 320px"></div>
</div>
