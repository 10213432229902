import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-monitoring',
  templateUrl: './monitoring.component.html',
  styleUrls: ['./monitoring.component.scss'],
})
export class MonitoringComponent implements OnInit {
  constructor() {}
  Nowtime = 17.5
  settime = 24.5
  ngOnInit(): void {}
  add() {
    this.settime = 1 + this.settime
  }
  reduce() {
    this.settime = this.settime - 1
  }
}
