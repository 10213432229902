import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { SharedModule } from 'src/app/shared.module'

import { ForgotPasswordComponent } from './Auth/forgot-password/forgot-password.component'
import { LockscreenComponent } from './Auth/lockscreen/lockscreen.component'
import { LoginComponent } from './Auth/login/login.component'
import { RegisterComponent } from './Auth/register/register.component'
import { Error404Component } from './Errors/404/404.component'
import { Error500Component } from './Errors/500/500.component'
import { WeatherComponent } from './weather/weather.component'
import { MapComponent } from '../../map/map.component'
import { RegionComponent } from './region/region.component'
import { ListComponent } from './list/list.component'
import { StandardComponent } from './standard/standard.component'
import { DailyComponent } from './daily/daily.component'
import { MonitoringComponent } from './monitoring/monitoring.component'
import { PiechartComponent } from './piechart/piechart.component'
import { DeviceNodeListComponent } from '../../device-node-list/device-node-list.component'
import { NgxEchartsModule } from 'ngx-echarts'

const COMPONENTS = [
  ForgotPasswordComponent,
  LockscreenComponent,
  LoginComponent,
  MapComponent,
  WeatherComponent,
  RegisterComponent,
  Error404Component,
  Error500Component,
  RegionComponent,
  ListComponent,
  StandardComponent,
  DailyComponent,
  MonitoringComponent,
  PiechartComponent,
  DeviceNodeListComponent,
]

@NgModule({
  imports: [SharedModule, FormsModule, ReactiveFormsModule, NgxEchartsModule],
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS],
})
export class SystemModule {}
