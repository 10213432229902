import { Component, OnInit, ElementRef } from '@angular/core'
import { NzPlacementType } from 'ng-zorro-antd/dropdown'

@Component({
  selector: 'app-region',
  templateUrl: './region.component.html',
  styleUrls: ['./region.component.scss'],
})
export class RegionComponent implements OnInit {
  constructor(private el: ElementRef) {}
  flag = true
  energyflag = true
  dailyflag = true
  humitureflag = false
  devicenodelistflag = false
  Intelligentlist = [
    {
      name: '温度调节监控',
    },
    {
      name: '区域智能监控',
    },
    {
      name: '项目温度监控',
    },
    {
      name: '项目节能监控',
    },
    {
      name: '热源分布监控',
    },
    {
      name: '实时天气预报',
    },
    {
      name: '更多....',
    },
  ]
  listOfPosition: NzPlacementType[] = ['bottomRight']
  ngOnInit(): void {}

  getwendu(data) {
    // console.log(data, '112')
    // this.Intelligentlist.map((val, index) => {
    //  if (val.name==data) {
    //      return  this.flag=false
    //    }
    // })
    if (data == '项目温度监控') {
      this.flag = false
    }
  }
  energy(data) {
    if (data == '项目节能监控') {
      this.energyflag = false
    }
  }
  getdaily(data) {
    if (data == '热源分布监控') {
      this.dailyflag = false
    }
  }
  humiture(data) {
    if (data == '温度调节监控') {
      this.humitureflag = false
    }
  }
  devicenodelist(data) {
    if (data == '设备列表') {
      this.devicenodelistflag = false
    }
  }
}
