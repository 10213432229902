import { Component } from '@angular/core'
import { select, Store } from '@ngrx/store'
import * as Reducers from 'src/app/store/reducers'
import { jwtAuthService } from '@service/jwt'
import { ActivatedRoute, Router } from '@angular/router'

@Component({
  selector: 'app-system-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent {
  logo: String
  authProvider: string = 'jwt'
  loading: boolean = false
  constructor(
    private store: Store<any>,
    private route: ActivatedRoute,
    private router: Router,
    public jwtAuthService: jwtAuthService,
  ) {
    this.store.pipe(select(Reducers.getSettings)).subscribe(state => {
      this.logo = state.logo
      this.authProvider = state.authProvider
    })
    this.store.pipe(select(Reducers.getUser)).subscribe(state => {
      this.loading = state.loading
    })
    this.route.queryParamMap.subscribe(params => {
      if (params.has('id_token')) {
        this.jwtAuthService.setToken(params.get('id_token'))
      }
      if (this.jwtAuthService.authenticated() && params.has('returnUrl')) {
        this.router.navigateByUrl(params.get('returnUrl'))
      } else {
        this.router.navigateByUrl('ui-kits/antd')
      }
    })
  }
}
