import { Component, OnInit, AfterViewInit } from '@angular/core'
import * as L7 from '@antv/l7'
import * as l7m from '@antv/l7-maps'

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit, AfterViewInit {
  ngOnInit() {}

  ngAfterViewInit() {
    console.log('l7 scene: ', L7.Scene)
    const scene = new L7.Scene({
      logoVisible: false,
      id: 'map',
      map: new l7m.GaodeMap({
        pitch: 0,
        style: 'dark',
        center: [105, 35],
        zoom: 4.5,
      }),
    })
    fetch('https://gw.alipayobjects.com/os/basement_prod/9078fd36-ce8d-4ee2-91bc-605db8315fdf.csv')
      .then(res => res.text())
      .then(data => {
        const pointLayer = new L7.PointLayer({})
          .source(data, {
            parser: {
              type: 'csv',
              x: 'Longitude',
              y: 'Latitude',
            },
          })
          .shape('circle')
          .active(true)
          .animate(true)
          .size(40)
          .color('#78b912')
          .style({
            opacity: 1,
          })
        pointLayer.on('mousemove', e => {
          const popup = new L7.Popup({
            offsets: [0, 0],
            closeButton: false,
          }).setLnglat(e.lngLat).setHTML(`
                    <div class="popup">
                        <h6><a href="e.feature.FullName">${e.feature.FullName}</a></h6>
                        <img src="https://rhthermo.com/static/media/school.4b56d3d6.png" alt="" width="100%" height="100%">
                        <h6>Longitude:${e.feature.Longitude}</h6>
                        <h6>Latitude:${e.feature.Latitude}</h6>
                    </div>
            `)
          scene.addPopup(popup)
        })
        scene.addLayer(pointLayer)
        const overlayers = {
          低能耗运转: pointLayer,
        }
        const layersControl = new L7.Layers({
          overlayers,
        })
        // 添加 Filter Control
        scene.addControl(layersControl)
      })

    const zoomControl = new L7.Zoom({
      position: 'topright',
    })
    // 添加 Zoom Control
    scene.addControl(zoomControl)

    const scaleControl = new L7.Scale({
      position: 'bottomright',
    })
    // 添加 Scale Control
    scene.addControl(scaleControl)
  }
}
export class MapModule {}
