import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-regional-energy-consumption',
  templateUrl: './regional-energy-consumption.component.html',
  styleUrls: ['./regional-energy-consumption.component.scss'],
})
export class RegionalEnergyConsumptionComponent implements OnInit {
  constructor() {}
  title: string = '各区域的能耗排名'
  column = [
    {
      title: '区域',
    },
    {
      title: '耗热量(w/m²)',
    },
    {
      title: '耗电量(kw/h)',
    },
    {
      title: '耗水量(m³)',
    },
  ]
  data = []

  ngOnInit(): void {
    this.data = [
      {
        area: '太原学院',
        heatConsumption: 57.1,
        waterConsumption: 1971,
        powerConsumption: 20.3,
      },
      {
        area: '同煤大厦',
        heatConsumption: 52.6,
        waterConsumption: 1826,
        powerConsumption: 19.5,
      },
      {
        area: '新世纪家园别墅区',
        heatConsumption: 49.5,
        waterConsumption: 3526,
        powerConsumption: 21.3,
      },
      {
        area: '北京假日阳光别墅区',
        heatConsumption: 40.1,
        waterConsumption: 1521,
        powerConsumption: 18.1,
      },
      {
        area: '沈阳别墅区',
        heatConsumption: 39.7,
        waterConsumption: 1699,
        powerConsumption: 19.6,
      },
    ]
  }
}
