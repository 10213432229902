<div class="monitoring">
  <div class="monitoring-wrapper">
    <div class="monitoring-header">
      <span class="monitoring-header-title">温度显示</span>
    </div>
    <div class="monitoring-content-wrapper">
      <div class="monitoring-content">
        <span>现在温度</span>
        <span>{{ Nowtime }}℃</span>
      </div>
      <div class="monitoring-content">
        <span>设定温度</span>
        <span>{{ settime }}℃</span>
      </div>
    </div>
    <div class="monitoring-operator-wrapper">
      <div class="monitoring-operator-header">
        <div class="monitoring-title">
          控制温度
        </div>
      </div>
      <div class="monitoring-operator monitoring-operator-add">
        <span>提高温度</span>
        <span class="iconfont icon-jiahaocu cursor" (click)="add()"></span>
      </div>
      <div class="monitoring-operator monitoring-operator-reduction">
        <span>减少温度</span>
        <span class="iconfont icon-jiansvg cursor" (click)="reduce()"></span>
      </div>
    </div>
  </div>
  <!--  <h4 class="content-container">-->
  <!--    <span>现在温度</span>-->
  <!--    <p>{{ Nowtime }}℃</p>-->
  <!--  </h4>-->
  <!--  <h4 class="content-container">-->
  <!--    <span>设定温度</span>-->
  <!--    <p>{{ settime }}℃</p>-->
  <!--  </h4>-->
  <!--  <div class="test">-->
  <!--    <span class="iconfont icon-jiahaocu cursor" (click)="add()"></span>-->
  <!--    <span class="iconfont icon-jiansvg cursor" (click)="reduce()"></span>-->
  <!--  </div>-->
</div>
