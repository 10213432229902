import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-temperature-chart',
  templateUrl: './temperature-chart.component.html',
  styleUrls: ['./temperature-chart.component.scss'],
})
export class TemperatureChartComponent implements OnInit {
  title: string = '项目温度监控表'
  constructor() {}
  chartOption: any
  ngOnInit(): void {
    this.chartOption = this.getData()
  }

  getData() {
    const data = {
      date: ['10:00', '10:30', '11:30', '12:00', '12:30'],
      temperature: [
        [28, 26, 27, 39, 37, 29, 31, 39],
        [24, 18, 28, 21, 37, 28, 27, 22],
        [20, 17, 21, 15, 28, 17, 22, 36],
        [11, 13, 12, 18, 31, 26, 36, 9],
      ],
    }
    return this.getOption(data.date, data.temperature)
  }

  getOption(data, seriesData) {
    let legend_color = ['#287f4e', '#13a9bd', '#f05f44', '#d47336']
    let legend_title = ['太原学院新校区', '新世纪家园别墅区', '山西同煤大厦', '北京假日阳光别墅区']
    let legends = legend_title.map((item, index) => {
      let x = index % 2 === 0 ? '10%' : '50%'
      let y = index > 1 ? '20' : '0'
      return {
        orient: 'horizontal',
        align: 'left',
        icon: 'rect',
        x: x,
        y: y,
        data: [item],
        textStyle: {
          color: `#fff`,
          fontSize: 16,
        },
      }
    })
    return {
      tooltip: {
        trigger: 'axis',
      },
      legend: legends,
      grid: {
        bottom: 30,
      },
      xAxis: {
        axisLabel: {
          interval: 0,
          textStyle: {
            color: '#13a9bd',
            fontStyle: 'normal',
            fontFamily: '微软雅黑',
            fontSize: 19,
          },
        },
        axisTick: {
          show: false,
          lineStyle: {
            color: '#808c96',
          },
        },
        axisLine: {
          lineStyle: {
            color: '#808c96',
            type: 'dotted',
          },
        },
        type: 'category',
        boundaryGap: false,
        data: data,
      },
      yAxis: {
        type: 'value',
        axisLine: {
          show: false,
        },
        axisLabel: {
          margin: 15,
          color: '#13a9bd',
          fontSize: 20,
        },
        axisTick: {
          show: false,
          lineStyle: {
            color: '#ffffff',
          },
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#ffffff',
            type: 'dotted',
          },
        },
      },
      series: [
        {
          name: '太原学院新校区',
          type: 'line',
          symbol: 'circle',
          color: `${legend_color[0]}`,
          data: seriesData[0],
          itemStyle: {},
        },
        {
          name: '新世纪家园别墅区',
          type: 'line',
          symbol: 'circle',
          color: `${legend_color[1]}`,
          data: seriesData[1],
          itemStyle: {},
        },
        {
          name: '山西同煤大厦',
          type: 'line',
          symbol: 'circle',
          color: `${legend_color[2]}`,
          data: seriesData[2],
          itemStyle: {},
        },
        {
          name: '北京假日阳光别墅区',
          type: 'line',
          symbol: 'circle',
          color: `${legend_color[3]}`,
          data: seriesData[3],
          itemStyle: {},
        },
      ],
    }
  }
}
