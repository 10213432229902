<div class="data-container">
  <div class="header-container">
    <span class="header">{{ header }}</span>
  </div>
  <div nz-row [nzGutter]="16">
    <div nz-col nzSpan="6" class="data-view-left-wrapper">
      <nz-card [nzBordered]="false" [nzBodyStyle]="{ padding: '0' }">
        <app-monitoring></app-monitoring>
      </nz-card>
      <nz-card class="gutter" [nzBordered]="false" [nzBodyStyle]="{ padding: '0' }">
        <app-piechart></app-piechart>
      </nz-card>
    </div>
    <div nz-col nzSpan="12" class="data-view-main-wrapper">
      <app-unity></app-unity>
      <nz-card [nzBordered]="false">
        <app-device-node-list></app-device-node-list>
      </nz-card>
    </div>
    <div nz-col nzSpan="6" class="data-view-right-wrapper">
      <nz-card [nzBodyStyle]="{ padding: '0' }" [nzBordered]="false">
        <app-standard></app-standard>
      </nz-card>
      <nz-card [nzBodyStyle]="{ padding: '0' }" [nzBordered]="false">
        <app-daily></app-daily>
      </nz-card>
    </div>
  </div>
</div>
