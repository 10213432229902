import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-project-dashboard',
  templateUrl: './project-dashboard.component.html',
  styleUrls: ['./project-dashboard.component.scss'],
})
export class ProjectDashboardComponent implements OnInit {
  header: string = '瑞海热控指挥能源云实时数据看板'
  constructor() {}

  ngOnInit(): void {}
}
