import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms'
import { select, Store } from '@ngrx/store'
import * as Reducers from 'src/app/store/reducers'
import * as UserActions from 'src/app/store/user/actions'
import { jwtAuthService } from '@service/jwt'

interface AppState {
  count: number
}

@Component({
  selector: 'app-system-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
})
export class RegisterComponent implements OnInit {
  checked: true
  size: 'large'
  validateForm!: FormGroup
  logo: String
  loading: boolean = false
  pasword_title: String
  constructor(
    private fb: FormBuilder,
    private store: Store<AppState>,
    public jwtAuthService: jwtAuthService,
  ) {
    this.validateForm = this.fb.group({
      email: [null, [Validators.email, Validators.required]],
      password: [null, [Validators.required]],
      checkPassword: [null, [Validators.required, this.confirmationValidator]],
      nickname: [null, [Validators.required]],
      phoneNumberPrefix: ['+86'],
      phoneNumber: [null, [Validators.required]],
      website: [null, [Validators.required]],
      captcha: [null, [Validators.required]],
      agree: [false],
    })
    this.store.pipe(select(Reducers.getUser)).subscribe(state => {
      this.loading = state.loading
    })
  }
  updateConfirmValidator(): void {
    Promise.resolve().then(() => this.validateForm.controls.checkPassword.updateValueAndValidity())
  }

  confirmationValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { required: true }
    } else if (control.value !== this.validateForm.controls.password.value) {
      return { confirm: true, error: true }
    }
    return {}
  }
  getCaptcha(e: MouseEvent): void {
    e.preventDefault()
  }
  get email() {
    return this.validateForm.controls.email
  }
  get password() {
    return this.validateForm.controls.password
  }
  get checkPassword() {
    return this.validateForm.controls.checkPassword
  }
  get phoneNumber() {
    return this.validateForm.controls.phoneNumber
  }
  get captcha() {
    return this.validateForm.controls.captcha
  }
  register(): void {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty()
      this.validateForm.controls[i].updateValueAndValidity()
    }
    if (
      this.email.value !== null &&
      this.password.value !== null &&
      this.checkPassword.value !== null &&
      this.phoneNumber.value !== null &&
      this.captcha.value !== null
    ) {
      if (this.password.value == this.checkPassword.value) {
        const payload = {
          email: this.email.value,
          password: this.password.value,
          checkPassword: this.checkPassword.value,
          phoneNumber: this.phoneNumber.value,
          captcha: this.captcha.value,
        }
        this.store.dispatch(new UserActions.Register(payload))
      } else {
        this.pasword_title = '请确认您两次密码输入一致'
      }
    }
  }
  ngOnInit(): void {}
}
