  <div class="map-container">
      <div class="project-list">
          <p>项目列表</p>
          <li>
            山西同煤大厦
            <span>待机模式</span>
          </li>
          <li>
            太原学院项目
            <span>待机模式</span>
          </li>
          <li>
            陕西隧道项目
            <span>待机模式</span>
          </li>
          <li>
            北京别墅区项目
            <span>项目准备中</span>
          </li>
          <li>
            上海大学
            <span>项目准备中</span>
          </li>
          <li>
            沈阳别墅项目
            <span>待机模式</span>
          </li>
      </div>
      <div class="color-legend">
          <ul>
            <li>
              <svg height="10" width="10">
              <circle cx="5" cy="5" r="4" fill="pink" />
              </svg>
              低能耗运转
            </li>
            <li>
              <svg height="10" width="10">
                <circle cx="5" cy="5" r="4" fill="orange" />
              </svg>
              中能耗运转
            </li>
            <li>
              <svg height="10" width="10">
                <circle cx="5" cy="5" r="4" fill="red" />
              </svg>
              运转异常
            </li>
            <li>
              <svg height="10" width="10">
                <circle cx="5" cy="5" r="4" fill="blue" />
              </svg>
              项目准备中
            </li>
            <li>
              <svg height="10" width="10">
                <circle cx="5" cy="5" r="4" fill="green" />
              </svg>
              待机模式
            </li>
          </ul>
      </div>
      <div id="map"></div>
  </div>


