import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

import { JwtHelperService } from '@auth0/angular-jwt'
import { jwtAuthService } from '@service/jwt'

interface param {
  access_token: string
  token_type: string
  expires_in: number
  id_token: string
}

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent implements OnInit {
  params: {} = null
  helper = new JwtHelperService()
  constructor(router: Router, route: ActivatedRoute, jwtAuthService: jwtAuthService) {
    let href = window.location.href
    let obj = {}
    // @ts-ignore
    href
      .slice(href.indexOf('?') + 1, href.length)
      .split('&')
      .forEach(item => {
        let param = item.split('=')
        let k = param[0]
        let v = param[1]
        obj[k] = v
      })
    this.params = obj
    const token = this.params['id_token']
    jwtAuthService.setToken(token)
    router.navigate(['/'])
  }

  ngOnInit(): void {}
}
