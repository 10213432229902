import { Injectable } from '@angular/core'
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router'
import { Observable } from 'rxjs'
import { jwtAuthService } from '@service/jwt'

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(public router: Router, public jwtAuthService: jwtAuthService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this.jwtAuthService.authenticated()) {
      return true
    } else {
      // this.router.navigate(['auth/login'], { queryParams: { returnUrl: state.url } })
      window.location.href =
        'https://rhthermo.bccastle.com:443/api/v1/oauth2/authorize?response_type=id_token token&scope=openid&client_id=IXkQdMafl2xka0HaBruWRoNTfFLYVNyg&redirect_uri=http://127.0.0.1:4200/%23/authenticated?&config_id=0cdd4eb5-1ac4-49ec-b8e3-d9a632e5255b'
      return false
    }
  }
}
