import { NgModule } from '@angular/core'
import { AreaStateComponent } from '@components/area-state/area-state.component'
import { NzTabsModule } from 'ng-zorro-antd/tabs'
import { NgxEchartsModule } from 'ngx-echarts'
import { CommonModule } from '@angular/common'
import { NzToolTipModule } from 'ng-zorro-antd/tooltip'

@NgModule({
  declarations: [AreaStateComponent],
  imports: [NzTabsModule, CommonModule, NgxEchartsModule, NzToolTipModule],
  exports: [AreaStateComponent],
})
export class AreaStateModule {}
