import { AfterViewInit, Component, OnInit } from '@angular/core'
import echarts from 'echarts'
import { HttpClient } from '@angular/common/http'

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent implements AfterViewInit {
  mostRecentSevenDate = []
  temperature = []
  constructor(private http: HttpClient) {}

  initgetwendu(date: any[], temperature: any[]) {
    // 指定图表的配置项和数据
    let myChart = echarts.init(document.getElementById('wenduList'))
    let option = {
      tooltip: {
        trigger: 'axis',
      },
      grid: {
        left: '3%',
        right: '4%',
        top: '2%',
        height: '280',
        containLabel: true,
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: date,
      },
      yAxis: {
        type: 'value',
        scale: 'true',
        min: 18,
        max: 25,
        splitnumber: 2,
      },
      series: [
        {
          name: '太原学院',
          type: 'line',
          stack: '总量',
          data: temperature,
        },
        {
          name: '忻州同煤大厦',
          type: 'line',
          stack: '总量1',
          data: [22, 18, 19, 23, 20, 24, 23],
        },
        {
          name: '北京假日阳光别墅',
          type: 'line',
          stack: '总量2',
          data: [23, 24, 23, 22, 22, 20, 23],
        },
        {
          name: '沈阳新世纪家园别墅',
          type: 'line',
          stack: '总量3',
          data: [20, 24, 22, 22, 24, 19, 21],
        },
        {
          name: '国家电网太原分公司高层宿舍',
          type: 'line',
          stack: '总量4',
          data: [18, 20, 21, 22, 22, 19, 20],
        },
      ],
    }
    // 使用刚指定的配置项和数据显示图表。
    myChart.setOption(option)

    window.addEventListener('resize', function() {
      myChart.resize()
    })
  }

  getSevenDaysTempatureData() {
    this.http.get(`/biapi?TypeId=1`).subscribe(data => {
      for (let i in data) {
        this.mostRecentSevenDate.push(data[i]['dt'])
        this.temperature.push(data[i]['tempa'])
      }
      this.initgetwendu(this.mostRecentSevenDate, this.temperature)
    })
  }

  ngAfterViewInit(): void {
    this.getSevenDaysTempatureData()
  }
}
