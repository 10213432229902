import { NgModule } from '@angular/core'
import { SharedModule } from 'src/app/shared.module'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { SystemModule } from 'src/app/components/ui/system/system.module'
// UI Kits
import { DashboardRoutingModule } from '@app/pages/dashboard/dashboard-routing.module'
import { DashboardComponent } from '@app/pages/dashboard/dashboard.component'
import { LayoutModule } from '@components/ui/layout/layout.module'
import { ProjectDashboardComponent } from './project-dashboard/project-dashboard.component'
import { ComponentsModule } from '@components/components.module'
import { FlexLayoutModule } from '@angular/flex-layout'

const COMPONENTS = [DashboardComponent, ProjectDashboardComponent]

@NgModule({
  imports: [
    SharedModule,
    DashboardRoutingModule,
    FormsModule,
    LayoutModule,
    ReactiveFormsModule,
    SystemModule,
    ComponentsModule,
    FlexLayoutModule,
  ],
  declarations: [...COMPONENTS],
})
export class DashboardModule {}
