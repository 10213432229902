import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-data-home-head',
  templateUrl: './data-home-head.component.html',
  styleUrls: ['./data-home-head.component.scss'],
})
export class DataHomeHeadComponent implements OnInit {
  constructor() {}
  ngOnInit(): void {}
}
