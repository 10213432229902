import { Component, EventEmitter, OnInit } from '@angular/core'
import { AreaStateService } from '@components/area-state/area-state.service'
import { NzTabComponent } from 'ng-zorro-antd'

interface DataStruct {
  id: number
  name: string
  item: any[]
  temperature: Temperature
}

interface Temperature {
  date: string[]
  external: string[] | number[]
  internal: string[] | number[]
}

interface tabsParam {
  index: number
  tab: NzTabComponent
}

@Component({
  selector: 'app-area-state',
  templateUrl: './area-state.component.html',
  styleUrls: ['./area-state.component.scss'],
})
export class AreaStateComponent implements OnInit {
  constructor(private areaStateService: AreaStateService) {}

  title: string = '项目供热状态'
  temperatureTitle: string = '室内外温度近七日变化'
  /**
   * 近七日温度 echarts options
   */
  temperatureOptions: object = {}
  /**
   * 项目展示 echarts options
   */
  areaRegionalOptions: object = {}
  /**
   * 项目所有数据
   */
  data: any[] = []
  /**
   * 当前选中的项目数据
   */
  currentData: DataStruct = this.data[0]
  lock: boolean = false

  ngOnInit(): void {
    this.getData()
  }

  getData() {
    this.data = this.areaStateService.getData()
    this.currentData = this.data[0]
    this.getTemperatureChartOptions()
    this.areaRegionalOptions = this.areaStateService.getMouseMoveChartOptions(this.currentData)
  }

  getTemperatureChartOptions() {
    this.temperatureOptions = this.areaStateService.getChartOption(
      this.currentData.temperature.date,
      this.currentData.temperature.external,
      this.currentData.temperature.internal,
    )
  }

  handleChartClick(event) {
    const status = event.data.tooltip.show
    if (status === false || this.lock === true) {
      return
    }
    this.lock = true
    const index = event.seriesIndex
    const options = this.areaStateService.getOptions(this.currentData, index)
    this.areaRegionalOptions = options
  }
  handleChartMouseOut(event) {
    if (!this.lock) {
      return
    }
    this.lock = false
    this.areaRegionalOptions = this.areaStateService.getMouseMoveChartOptions(this.currentData)
  }

  handleTabsSelectChange(param: tabsParam) {
    this.currentData = this.data[param.index]
    this.areaRegionalOptions = this.areaStateService.getMouseMoveChartOptions(this.currentData)
    this.getTemperatureChartOptions()
  }
}
