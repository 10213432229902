import { Component, EventEmitter } from '@angular/core'
import {
  NzTableFilterFn,
  NzTableFilterList,
  NzTableQueryParams,
  NzTableSortFn,
  NzTableSortOrder,
} from 'ng-zorro-antd/table'
import { HttpClient, HttpParams } from '@angular/common/http'
import { any } from 'codelyzer/util/function'

interface DataItem {
  NodeId: string
  NodeName: string
  NbrOfSubNodes: string
  SubnodeId: string
  SubnodeName: string
  NbrOfTerminal: string
  TerminalId: string
  LocationId: string
  TempCalPositive: string
  TempCalNegative: string
  TerminalTypeId: string
  EnvTemperature: string
  RunningType: string
  RunningDT: string
}

interface ColumnItem {
  name: string
  sortOrder: NzTableSortOrder | null
  sortFn: NzTableSortFn | null
  listOfFilter: NzTableFilterList
  filterFn: NzTableFilterFn | null
  filterMultiple: boolean
  sortDirections: NzTableSortOrder[]
}

@Component({
  selector: 'app-device-node-list',
  templateUrl: './device-node-list.component.html',
  styleUrls: ['./device-node-list.component.scss'],
})
export class DeviceNodeListComponent {
  searchValue = ''
  visible = false
  listOfData: DataItem[]
  listOfDisplayData: DataItem[]
  loading = true
  pageIndex = 1
  total = 1
  pageSize = 15

  constructor(private http: HttpClient) {}

  loadDataFromServer(pageIndex: number): void {
    this.loading = true
    const url = '/alicloud/eheating/device/list'
    let params = new HttpParams()
      .set('appCode', '3cbf3469059b491a91c7f2a43889d381')
      .set('page_id', '' + pageIndex) //Create new HttpParams
    this.http.get(url, { params: params }).subscribe(data => {
      this.loading = false
      // @ts-ignore
      this.listOfData = data.data as DataItem[]
      // @ts-ignore
      this.listOfDisplayData = data.data
    })
  }

  onQueryParamsChange(params: any): void {
    let page = params.pageIndex || params
    this.loadDataFromServer(page)
  }

  reset(): void {
    this.searchValue = ''
    this.search()
  }

  search(): void {
    this.visible = false
    this.listOfDisplayData = this.listOfData.filter(
      (item: DataItem) => item.NodeName.indexOf(this.searchValue) !== -1,
    )
  }

  ngOnInit(): void {
    // this.loadDataFromServer(this.pageIndex)
    this.loadTotalPage()
  }

  loadTotalPage(): void {
    const url = '/alicloud/eheating/config/'
    http: const params = new HttpParams()
      .set('config_key', 'device_num_tol')
      .set('appCode', '3cbf3469059b491a91c7f2a43889d381')
    this.http.get(url, { params }).subscribe(data => {
      // @ts-ignore
      this.total = data.data[0].config_value
    })
  }
}
export class DeviceNodeListModule {}
