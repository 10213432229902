<app-data-chart-box [title]="title">
  <div class="table-wrapper">
    <div class="table-head-wrapper">
      <div class="table-head">
        <div class="table-head-item" *ngFor="let item of column">
          <span>{{ item.title }}</span>
        </div>
      </div>
    </div>
    <div class="table-body-wrapper">
      <div class="table-body-columns">
        <div class="table-body-column" *ngFor="let item of data">
          <div class="table-body-column-item" nz-tooltip [nzTooltipTitle]="item.area">
            <span>{{ item.area }}</span>
          </div>
          <div class="table-body-column-item">
            <span>{{ item.heatConsumption }}</span>
          </div>
          <div class="table-body-column-item">
            <span>{{ item.waterConsumption }}</span>
          </div>
          <div class="table-body-column-item">
            <span>{{ item.powerConsumption }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-data-chart-box>
