<div class="node-list-container">
  <nz-table
    #headerTable
    [nzData]="listOfDisplayData"
    [nzShowPagination]="false"
    [nzLoading]="loading"
    [nzTotal]="total"
    [nzPageSize]="pageSize"
    [nzPageIndex]="pageIndex"
    [nzScroll]="{ y: '150px' }"
    (nzQueryParams)="onQueryParamsChange($event)"
  >
    <thead>
      <tr>
        <th nzWidth="100px">设备ID</th>
        <th nzCustomFilter nzWidth="100px">
          设备位置
          <nz-filter-trigger
            [(nzVisible)]="visible"
            [nzActive]="searchValue.length > 0"
            [nzDropdownMenu]="searchmenu"
          >
            <i nz-icon nzType="search"></i>
          </nz-filter-trigger>
        </th>
        <th nzWidth="100px">运行状态</th>
        <th nzWidth="100px">环境温度</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of headerTable.data">
        <td>
          {{ data.device_id }}
        </td>
        <td>{{ data.device_location }}</td>
        <td>{{ data.device_status }}</td>
        <td>{{ data.device_temperature }}</td>
      </tr>
    </tbody>
  </nz-table>
  <nz-pagination
    [nzPageIndex]="pageIndex"
    [nzTotal]="total"
    (nzPageIndexChange)="onQueryParamsChange($event)"
    [nzPageSize]="pageSize"
  ></nz-pagination>
  <nz-dropdown-menu nzDropdownMenu #searchmenu="nzDropdownMenu">
    <div class="ant-table-filter-dropdown">
      <div class="search-box">
        <input type="text" nz-input placeholder="设备名称关键字" [(ngModel)]="searchValue" />
        <button nz-button nzSize="small" nzType="primary" (click)="search()" class="search-button">
          搜索
        </button>
        <button nz-button nzSize="small" (click)="reset()">重置</button>
      </div>
    </div>
  </nz-dropdown-menu>
</div>
