import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-data-home-statistics',
  templateUrl: './data-home-statistics.component.html',
  styleUrls: ['./data-home-statistics.component.scss'],
})
export class DataHomeStatisticsComponent implements OnInit {
  title: string = '数据可视化'
  constructor() {}
  data = [
    {
      name: '运行设备总数',
      val: 1000,
    },
    {
      name: '待机设备总数',
      val: 2000,
    },
    {
      name: '总加热时长',
      val: 2000,
    },
  ]
  ngOnInit(): void {}
}
