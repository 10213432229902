import { Component, OnInit } from '@angular/core'
import echarts from 'echarts'
@Component({
  selector: 'app-piechart',
  templateUrl: './piechart.component.html',
  styleUrls: ['./piechart.component.scss'],
})
export class PiechartComponent implements OnInit {
  constructor() {}
  private echartsDefaultColor: string = '#fff'
  piechartOption: any

  initPiechart() {
    this.piechartOption = {
      backgroundColor: '',

      title: {
        text: '热能分布总占比',
        left: 'center',
        top: 20,
        textStyle: {
          color: this.echartsDefaultColor,
        },
      },

      tooltip: {
        formatter: '{b}: {c} ({d}%)',
        trigger: 'item',
      },

      // visualMap: {
      //   show: false,
      //   min: 80,
      //   max: 600,
      //   inRange: {
      //     color: ['#feb638', '#16a188', '#66fefe', '#468da0', '#f58c54'],
      //     colorLightness: [0, 1],
      //   },
      // },
      series: [
        {
          name: '热能来源',
          type: 'pie',
          radius: '40%',
          center: ['50%', '50%'],
          data: [
            { value: 355, name: '发热电缆' },
            { value: 310, name: '电伴热' },
            { value: 274, name: '空气源热泵' },
            { value: 235, name: '其他' },
            { value: 400, name: '燃气锅炉' },
          ].sort(function(a, b) {
            return a.value - b.value
          }),
          roseType: 'radius',
          label: {
            color: this.echartsDefaultColor,
          },
          labelLine: {
            lineStyle: {
              color: this.echartsDefaultColor,
            },
            smooth: 0.2,
            length: 10,
            length2: 20,
          },
          // itemStyle: {
          //   color: '#c23531',
          //   shadowBlur: 200,
          //   shadowColor: '#811200',
          // },
          // color: ['#feb638', '#16a188', '#66fefe', '#468da0', '#f58c54'],

          animationType: 'scale',
          animationEasing: 'elasticOut',
          animationDelay: function(idx) {
            return Math.random() * 200
          },
        },
      ],

      color: ['#feb638', '#16a188', '#66fefe', '#468da0', '#f58c54'],
    }
  }
  ngOnInit(): void {
    this.initPiechart()
  }
}
