import { Component, OnInit } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { dateFormat } from 'src/app/common/Format'

@Component({
  selector: 'app-weather',
  templateUrl: './weather.component.html',
  styleUrls: ['./weather.component.scss'],
})
export class WeatherComponent implements OnInit {
  date: string
  code: number
  taiyuancity: any[]
  beijingcity: any[]
  shanghaicity: any[]
  shenyangcity: any[]
  xiancity: any[]
  name: string
  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    let date = new Date()
    this.date = dateFormat('YYYY/mm/dd', date)
    //太原
    this.http
      .get(`/api?key=SdG9HDzRjIqDYl8qs&location=taiyuan&language=zh-Hans&unit=c`)
      .subscribe(res => {
        this.code = res['results'][0].now.code
        this.taiyuancity = res['results']
      })
    //北京
    this.http
      .get(`/api?key=SdG9HDzRjIqDYl8qs&location=beijing&language=zh-Hans&unit=c`)
      .subscribe(res => {
        this.beijingcity = res['results']
      })
    //上海
    this.http
      .get(`/api?key=SdG9HDzRjIqDYl8qs&location=shanghai&language=zh-Hans&unit=c`)
      .subscribe(res => {
        this.shanghaicity = res['results']
      })
    //沈阳
    this.http
      .get(`/api?key=SdG9HDzRjIqDYl8qs&location=shenyang&language=zh-Hans&unit=c`)
      .subscribe(res => {
        this.shenyangcity = res['results']
      })
    //西安
    this.http
      .get(`/api?key=SdG9HDzRjIqDYl8qs&location=xian&language=zh-Hans&unit=c`)
      .subscribe(res => {
        this.xiancity = res['results']
      })
  }
}
