import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { UnityComponent } from '@components/unity/unity.component'
import { DataHomeHeadComponent } from './data-home-head/data-home-head.component'
import { TemperatureChartComponent } from './temperature-chart/temperature-chart.component'
import { DataChartBoxComponent } from './data-chart-box/data-chart-box.component'
import { RegionalEnergyConsumptionComponent } from './regional-energy-consumption/regional-energy-consumption.component'
import { NgxEchartsModule } from 'ngx-echarts'
import { AreaStateComponent } from './area-state/area-state.component'
import { AreaStateModule } from '@components/area-state/area-state.module'
import { DataHomeStatisticsComponent } from './data-home-statistics/data-home-statistics.component'
import { DataHomeMapComponent } from './data-home-map/data-home-map.component'
import { NzToolTipModule } from 'ng-zorro-antd/tooltip'

@NgModule({
  declarations: [
    UnityComponent,
    DataHomeHeadComponent,
    TemperatureChartComponent,
    DataChartBoxComponent,
    RegionalEnergyConsumptionComponent,
    DataHomeStatisticsComponent,
    DataHomeMapComponent,
  ],
  imports: [CommonModule, NgxEchartsModule, AreaStateModule, NzToolTipModule],
  exports: [
    UnityComponent,
    DataHomeHeadComponent,
    TemperatureChartComponent,
    RegionalEnergyConsumptionComponent,
    AreaStateComponent,
    DataHomeStatisticsComponent,
    DataHomeMapComponent,
  ],
})
export class ComponentsModule {}
