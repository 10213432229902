import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'app-data-chart-box',
  templateUrl: './data-chart-box.component.html',
  styleUrls: ['./data-chart-box.component.scss'],
})
export class DataChartBoxComponent implements OnInit {
  @Input() title: string = ''

  constructor() {}

  ngOnInit(): void {}
}
