export const getMenuData: any[] = [
  {
    category: true,
    title: '功能界面',
  },
  {
    title: '控制界面',
    key: 'kongzhi',
    icon: 'fa fa-tachometer',
    url: '/dashboard',
    children: [
      {
        title: '区域总控制台',
        key: 'kongzhi',
        icon: 'fa fa-tachometer',
        url: '/dashboard',
      },
      {
        title: '项目',
        key: 'quyu',
        icon: 'fa fa-map',
        url: '/dashboard/region',
        children: [
          {
            title: '山西项目导航',
            key: 'shanxi',
            url: '/dashboard/region/shanxi',
            children: [
              {
                title: '太原学院项目',
                key: 'taiyuanxueyuan',
                url: '/dashboard/region/shanxi/1',
              },
              {
                title: '山西同煤大厦',
                key: 'shanxitongmeidasha',
                url: '/dashboard/region/shanxi/2',
              },
            ],
          },
          {
            title: '陕西项目导航',
            key: 'shaanxi',
            url: '/dashboard/region/shaanxi',
            children: [],
          },
          {
            title: '北京项目导航',
            key: 'beijing',
            url: '/dashboard/region/beijing',
            children: [],
          },
          {
            title: '上海项目导航',
            key: 'shanghai',
            url: '/dashboard/region/shanghai',
            children: [],
          },
        ],
      },
    ],
  },
  {
    title: '其它导航',
    icon: 'fa',
    key: 'qita',
    url: '/other',
  },
]
