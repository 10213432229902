import { Component, ElementRef, OnInit } from '@angular/core'
import echarts from 'echarts'
import { HttpClient, HttpParams } from '@angular/common/http'

@Component({
  selector: 'app-daily',
  templateUrl: './daily.component.html',
  styleUrls: ['./daily.component.scss'],
})
export class DailyComponent implements OnInit {
  hours = []
  datacounts = []
  constructor(private elf: ElementRef, private http: HttpClient) {}

  chartOption: any

  initdaily(hours: any[], datacounts: any[]) {
    // 使用刚指定的配置项和数据显示图表。
    this.chartOption = {
      tooltip: {
        trigger: 'axis',
      },
      grid: {
        containLabel: true,
      },
      xAxis: {
        axisLabel: {
          interval: 0,
          formatter: value => {
            return value
              .split('')
              .splice(5)
              .join('')
          },
          textStyle: {
            color: '#ffffff',
            fontStyle: 'normal',
            fontFamily: '微软雅黑',
            fontSize: 12,
          },
        },
        axisLine: {
          lineStyle: {
            color: '#CECECE',
          },
        },
        type: 'category',
        boundaryGap: false,
        // data: hours,
        data: [
          '2021-03-26',
          '2021-03-27',
          '2021-03-28',
          '2021-03-29',
          '2021-03-30',
          '2021-03-31',
          '2021-04-01',
        ],
      },
      yAxis: {
        type: 'value',
        scale: 'true',
        splitNumber: 5,
        axisLabel: {
          textStyle: {
            color: '#a8aab0',
            fontStyle: 'normal',
            fontFamily: '微软雅黑',
            fontSize: 12,
          },
        },
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#E5E9ED',
            // 	opacity:0.1
          },
        },
      },
      series: [
        {
          type: 'line',
          symbol: 'circle',
          color: '#C24043',
          data: datacounts,
          smooth: true,
          itemStyle: {},
          areaStyle: {
            normal: {
              color: {
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: '#dc3881',
                  },
                  {
                    offset: 0.7,
                    color: 'rgba(220,56,129,0)',
                  },
                ],
                globalCoord: false, // 缺省为 false
              },
            },
          },
        },
      ],
    }
  }

  getHoursDataCountData() {
    // 3cbf3469059b491a91c7f2a43889d381&id=
    HttpParams: const params = new HttpParams()
      .set('appCode', '3cbf3469059b491a91c7f2a43889d381')
      .set('id', '1')

    this.http.get(`/alicloud/eheating/ana/data_tunnels_l7d`, { params }).subscribe(res => {
      // @ts-ignore
      const resData = res.data
      for (let item of resData) {
        this.hours.push(item.bizdata)
        this.datacounts.push(item.data_tunnels)
      }
      this.initdaily(this.hours, this.datacounts)
      // for (let i in data) {
      //   this.hours.push(data[i]['hour'])
      //   this.datacounts.push(data[i]['datacounts'])
      // }
      // this.initdaily(this.hours, this.datacounts)
    })
  }

  ngOnInit(): void {
    this.getHoursDataCountData()
  }
}
