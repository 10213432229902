import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class AreaStateService {
  constructor() {}
  items_colors = [
    [
      '#1d4c26',
      '#3fb95b',
      '#576b5a',
      '#8cb594',
      '#508e75',
      '#babf61',
      '#5f6839',
      '#b3cb51',
      '#8b9c7c',
      '#503c3e',
    ],
    [
      '#13a9bd',
      '#1ce1e5',
      '#0e6666',
      '#93b7ba',
      '#374e5e',
      '#618ea5',
      '#9eb3ce',
      '#b0a1be',
      '#899e95',
      '#e0be90',
    ],
    [
      '#f05f44',
      '#edb9b2',
      '#8e6d68',
      '#c76331',
      '#cc8239',
      '#d9b267',
      '#efd8b6',
      '#c15950',
      '#d9a43c',
      '#9a5d3e',
    ],
    [
      '#ffffff',
      '#adadad',
      '#5b5b5b',
      '#333333',
      '#2e3238',
      '#3b4850',
      '#81908b',
      '#dadfd9',
      '#827d77',
      '#969a99',
    ],
  ]
  getData(): any[] {
    const data = [
      {
        id: 1,
        name: '太原学院',
        item: [
          {
            name: '供热面积',
            data: {
              val: 701,
              total: 1000,
            },
            item: [
              {
                name: '走廊供暖面积',
                val: 259,
              },
              {
                name: '教学楼供暖面积',
                val: 142,
              },
              {
                name: '宿舍供暖面积',
                val: 118,
              },
              {
                name: '卫生间供暖面积',
                val: 182,
              },
            ],
          },
          {
            name: '供热设备',
            data: {
              val: 814,
              total: 1000,
            },
            item: [
              {
                name: '走廊供暖面积',
                val: 321,
              },
              {
                name: '教学楼供暖面积',
                val: 192,
              },
              {
                name: '宿舍供暖面积',
                val: 112,
              },
              {
                name: '卫生间供暖面积',
                val: 189,
              },
            ],
          },
          {
            name: '加热设备配比',
            data: {
              val: 782,
              total: 1000,
            },
            item: [
              {
                name: '走廊供暖面积',
                val: 248,
              },
              {
                name: '教学楼供暖面积',
                val: 196,
              },
              {
                name: '宿舍供暖面积',
                val: 132,
              },
              {
                name: '卫生间供暖面积',
                val: 206,
              },
            ],
          },
          {
            name: '能耗配比',
            data: {
              val: 829,
              total: 1000,
            },
            item: [
              {
                name: '走廊供暖面积',
                val: 283,
              },
              {
                name: '教学楼供暖面积',
                val: 206,
              },
              {
                name: '宿舍供暖面积',
                val: 105,
              },
              {
                name: '卫生间供暖面积',
                val: 235,
              },
            ],
          },
        ],
        indicators: [
          {
            name: '总面积(m²)',
            val: 2285,
          },
          {
            name: '单位能耗(w/m²)',
            val: 115,
          },
          {
            name: '室外温度(℃)',
            val: 18,
          },
          {
            name: '平均室温(℃)',
            val: 21,
          },
        ],
        temperature: {
          date: ['5.14', '5.15', '5.16', '5.17', '5.18', '5.19', '5.20'],
          external: [26.13, 21.56, 22.21, 27.69, 33.9, 24.95, 31.13],
          internal: [23.8, 37.84, 26.54, 30.73, 36.17, 34.5, 20.93],
        },
      },
      {
        id: 11,
        name: '同煤大厦',
        item: [
          {
            name: '供热面积',
            data: {
              val: 829,
              total: 1000,
            },
            item: [
              {
                name: '加热中',
                val: 218,
              },
              {
                name: '闲置',
                val: 238,
              },
              {
                name: '故障',
                val: 373,
              },
            ],
          },
          {
            name: '供热设备',
            data: {
              val: 772,
              total: 1000,
            },
            item: [
              {
                name: '加热中',
                val: 268,
              },
              {
                name: '闲置',
                val: 175,
              },
              {
                name: '故障',
                val: 329,
              },
            ],
          },
          {
            name: '加热设备配比',
            data: {
              val: 720,
              total: 1000,
            },
            item: [
              {
                name: '加热中',
                val: 212,
              },
              {
                name: '闲置',
                val: 138,
              },
              {
                name: '故障',
                val: 370,
              },
            ],
          },
          {
            name: '能耗配比',
            data: {
              val: 794,
              total: 1000,
            },
            item: [
              {
                name: '加热中',
                val: 223,
              },
              {
                name: '闲置',
                val: 181,
              },
              {
                name: '故障',
                val: 390,
              },
            ],
          },
        ],
        indicators: [
          {
            name: '总面积(m²)',
            val: 3040,
          },
          {
            name: '单位能耗(w/m²)',
            val: 137,
          },
          {
            name: '室外温度(℃)',
            val: 21,
          },
          {
            name: '平均室温(℃)',
            val: 22,
          },
        ],
        temperature: {
          date: ['5.14', '5.15', '5.16', '5.17', '5.18', '5.19', '5.20'],
          external: [24.69, 21.73, 25.71, 27.76, 29.14, 34.74, 33.67],
          internal: [33.76, 22.2, 26.09, 32.27, 36.58, 29.89, 37.04],
        },
      },
      {
        id: 21,
        name: '新世纪家园别墅区',
        item: [
          {
            name: '供热面积',
            data: {
              val: 883,
              total: 1000,
            },
            item: [
              {
                name: '壁挂炉',
                val: 264,
              },
              {
                name: '电暖器',
                val: 165,
              },
              {
                name: '加热电缆',
                val: 454,
              },
            ],
          },
          {
            name: '供热设备',
            data: {
              val: 839,
              total: 1000,
            },
            item: [
              {
                name: '壁挂炉',
                val: 317,
              },
              {
                name: '电暖器',
                val: 191,
              },
              {
                name: '加热电缆',
                val: 331,
              },
            ],
          },
          {
            name: '加热设备配比',
            data: {
              val: 770,
              total: 1000,
            },
            item: [
              {
                name: '壁挂炉',
                val: 231,
              },
              {
                name: '电暖器',
                val: 209,
              },
              {
                name: '加热电缆',
                val: 330,
              },
            ],
          },
          {
            name: '能耗配比',
            data: {
              val: 727,
              total: 1000,
            },
            item: [
              {
                name: '壁挂炉',
                val: 195,
              },
              {
                name: '电暖器',
                val: 179,
              },
              {
                name: '加热电缆',
                val: 353,
              },
            ],
          },
        ],
        indicators: [
          {
            name: '总面积(m²)',
            val: 2562,
          },
          {
            name: '单位能耗(w/m²)',
            val: 166,
          },
          {
            name: '室外温度(℃)',
            val: 21,
          },
          {
            name: '平均室温(℃)',
            val: 21,
          },
        ],
        temperature: {
          date: ['5.14', '5.15', '5.16', '5.17', '5.18', '5.19', '5.20'],
          external: [22.75, 26.87, 27.52, 38.09, 25.33, 30.86, 24.09],
          internal: [20.45, 21.48, 38.04, 30.96, 26.25, 30.9, 32.49],
        },
      },
      {
        id: 31,
        name: '北京假日阳光别墅区',
        item: [
          {
            name: '供热面积',
            data: {
              val: 778,
              total: 1000,
            },
            item: [
              {
                name: '电能',
                val: 267,
              },
              {
                name: '热能',
                val: 511,
              },
            ],
          },
          {
            name: '供热设备',
            data: {
              val: 810,
              total: 1000,
            },
            item: [
              {
                name: '电能',
                val: 317,
              },
              {
                name: '热能',
                val: 493,
              },
            ],
          },
          {
            name: '加热设备配比',
            data: {
              val: 716,
              total: 1000,
            },
            item: [
              {
                name: '电能',
                val: 231,
              },
              {
                name: '热能',
                val: 485,
              },
            ],
          },
          {
            name: '能耗配比',
            data: {
              val: 875,
              total: 1000,
            },
            item: [
              {
                name: '电能',
                val: 321,
              },
              {
                name: '热能',
                val: 554,
              },
            ],
          },
        ],
        indicators: [
          {
            name: '总面积(m²)',
            val: 2777,
          },
          {
            name: '单位能耗(w/m²)',
            val: 108,
          },
          {
            name: '室外温度(℃)',
            val: 21,
          },
          {
            name: '平均室温(℃)',
            val: 21,
          },
        ],
        temperature: {
          date: ['5.14', '5.15', '5.16', '5.17', '5.18', '5.19', '5.20'],
          external: [33.9, 26.59, 35.23, 23.24, 27.39, 30.41, 23.75],
          internal: [23.89, 26.21, 31.84, 38.89, 22.8, 34.1, 38.45],
        },
      },
    ]
    return data.map(item => {
      const left = item.indicators.splice(0, 2)
      const right = item.indicators.splice(0, 2)
      item.indicators = {
        // @ts-ignore
        left,
        right,
      }
      return item
    })
  }

  getMouseMoveChartOptions(data): object {
    const default_color = 'rgba(3, 31, 56, .8)'
    const items = data.item
    const colors = ['#287f4e', '#13a9bd', '#f05f44', '#fff']
    const series = items.map((item, index) => {
      const pie_begin = (index * 2 + 1) * 10
      return {
        name: item.name,
        startAngle: (Math.random() * 360).toFixed(0),
        type: 'pie',
        radius: [`${pie_begin}%`, `${pie_begin + 5}%`],
        label: {
          show: false,
        },
        data: [
          {
            label: {
              show: false,
            },
            tooltip: {},
            labelLine: {
              show: false,
              showAbove: index % 2 === 1 ? true : false,
              lineStyle: {
                color: colors[index],
              },
            },
            value: item.data.val,
            name: item.name,
            itemStyle: {
              color: colors[index],
            },
          },
          {
            value: item.data.total - item.data.val,
            name: item.name + index,
            tooltip: {
              show: false,
            },
            itemStyle: {
              color: default_color,
            },
            labelLine: {
              show: false,
            },
            label: {
              show: false,
            },
          },
        ],
        animationType: 'expansion',
        animation: true,
        emphasis: {
          scale: false,
        },
      }
    })
    return {
      tooltip: {},
      legend: {
        show: false,
      },
      series: series,
    }
  }

  getChartOption(xaxis, external, internal): object {
    return {
      legend: {
        icon: 'rect',
        data: ['室外温度(℃)', '室内温度(℃)'],
        itemGap: 44,
        textStyle: {
          color: '#ffffff',
          fontSize: 16,
        },
      },
      xAxis: {
        axisLabel: {
          interval: 0,
          textStyle: {
            color: '#13a9bd',
            fontStyle: 'normal',
            fontFamily: '微软雅黑',
            fontSize: 19,
          },
        },
        axisTick: {
          show: false,
          lineStyle: {
            color: '#808c96',
          },
        },
        axisLine: {
          lineStyle: {
            color: '#808c96',
          },
        },
        type: 'category',
        boundaryGap: false,
        data: xaxis,
      },
      yAxis: {
        type: 'value',
        axisLine: {
          show: false,
        },
        axisLabel: {
          margin: 15,
          color: '#13a9bd',
          fontSize: 20,
        },
        axisTick: {
          show: false,
          lineStyle: {
            color: '#ffffff',
          },
        },
      },
      series: [
        {
          name: '室外温度(℃)',
          data: external,
          type: 'line',
          color: '#13a9bd',
        },
        {
          name: '室内温度(℃)',
          data: internal,
          type: 'line',
          color: '#d47336',
        },
      ],
    }
  }

  getOptions(data, index): object {
    const items = data.item[index]
    const data_source = items.item
    const colors = this.items_colors[index]
    const information = data_source.map((item, index) => {
      return {
        value: item.val,
        name: `${item.name}`,
        itemStyle: {
          color: colors[index % 10],
        },
      }
    })
    return {
      tooltip: {},
      legend: {
        show: true,
        bottom: 0,
        textStyle: {
          color: '#fff',
        },
      },
      series: [
        {
          center: ['50%', '45%'],
          radius: [0, '70%'],
          name: items.name,
          type: 'pie',
          label: {
            show: true,
          },
          data: information,
          animation: false,
          emphasis: {
            scale: false,
          },
        },
      ],
    }
  }
}
