<div class="wrap" fxLayout="column">
  <div>
    <p class="taiyuan taiyuanshi">
      <span>太原市</span>
      <span class="time">{{ date }}</span>
    </p>
    <ul class="tiayuan_title" *ngFor="let item of taiyuancity">
      <li>
        <span *ngIf="item.now.code == 0">
          <img class="img1" src="../../../../assets/images/white/0@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 1">
          <img class="img1" src="../../../../assets/images/white/1@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 2">
          <img class="img1" src="../../../../assets/images/white/2@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 3">
          <img class="img1" src="../../../../assets/images/white/3@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 4">
          <img class="img1" src="../../../../assets/images/white/4@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 5">
          <img class="img1" src="../../../../assets/images/white/5@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 6">
          <img class="img1" src="../../../../assets/images/white/6@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 7">
          <img class="img1" src="../../../../assets/images/white/7@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 8">
          <img class="img1" src="../../../../assets/images/white/8@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 9">
          <img class="img1" src="../../../../assets/images/white/9@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 10">
          <img class="img1" src="../../../../assets/images/white/10@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 11">
          <img class="img1" src="../../../../assets/images/white/11@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 12">
          <img class="img1" src="../../../../assets/images/white/12@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 13">
          <img class="img1" src="../../../../assets/images/white/13@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 14">
          <img class="img1" src="../../../../assets/images/white/14@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 15">
          <img class="img1" src="../../../../assets/images/white/15@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 16">
          <img class="img1" src="../../../../assets/images/white/16@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 17">
          <img class="img1" src="../../../../assets/images/white/17@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 18">
          <img class="img1" src="../../../../assets/images/white/18@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 19">
          <img class="img1" src="../../../../assets/images/white/19@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 20">
          <img class="img1" src="../../../../assets/images/white/20@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 21">
          <img class="img1" src="../../../../assets/images/white/21@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 22">
          <img class="img1" src="../../../../assets/images/white/22@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 23">
          <img class="img1" src="../../../../assets/images/white/23@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 24">
          <img class="img1" src="../../../../assets/images/white/24@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 25">
          <img class="img1" src="../../../../assets/images/white/25@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 26">
          <img class="img1" src="../../../../assets/images/white/26@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 27">
          <img class="img1" src="../../../../assets/images/white/27@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 28">
          <img class="img1" src="../../../../assets/images/white/28@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 29">
          <img class="img1" src="../../../../assets/images/white/29@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 30">
          <img class="img1" src="../../../../assets/images/white/30@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 31">
          <img class="img1" src="../../../../assets/images/white/31@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 32">
          <img class="img1" src="../../../../assets/images/white/32@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 33">
          <img class="img1" src="../../../../assets/images/white/33@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 34">
          <img class="img1" src="../../../../assets/images/white/34@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 35">
          <img class="img1" src="../../../../assets/images/white/35@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 36">
          <img class="img1" src="../../../../assets/images/white/36@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 37">
          <img class="img1" src="../../../../assets/images/white/37@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 38">
          <img class="img1" src="../../../../assets/images/white/38@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 99">
          <img class="img1" src="../../../../assets/images/white/99@1x.png" alt="" />
        </span>
      </li>
      <li class="wendu">
        <span> {{ item.now.temperature }}℃</span>
      </li>
    </ul>
    <ol class="newcity" *ngFor="let item of beijingcity">
      <li class="taiyuan">北京市</li>
      <li class="newcity_text">
        <span *ngIf="item.now.code == 0">
          <img class="pic" src="../../../../assets/images/white/0@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 1">
          <img class="pic" src="../../../../assets/images/white/1@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 2">
          <img class="pic" src="../../../../assets/images/white/2@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 3">
          <img class="pic" src="../../../../assets/images/white/3@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 4">
          <img class="pic" src="../../../../assets/images/white/4@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 5">
          <img class="pic" src="../../../../assets/images/white/5@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 6">
          <img class="pic" src="../../../../assets/images/white/6@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 7">
          <img class="pic" src="../../../../assets/images/white/7@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 8">
          <img class="pic" src="../../../../assets/images/white/8@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 9">
          <img class="pic" src="../../../../assets/images/white/9@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 10">
          <img class="pic" src="../../../../assets/images/white/10@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 11">
          <img class="pic" src="../../../../assets/images/white/11@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 12">
          <img class="pic" src="../../../../assets/images/white/12@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 13">
          <img class="pic" src="../../../../assets/images/white/13@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 14">
          <img class="pic" src="../../../../assets/images/white/14@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 15">
          <img class="pic" src="../../../../assets/images/white/15@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 16">
          <img class="pic" src="../../../../assets/images/white/16@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 17">
          <img class="pic" src="../../../../assets/images/white/17@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 18">
          <img class="pic" src="../../../../assets/images/white/18@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 19">
          <img class="pic" src="../../../../assets/images/white/19@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 20">
          <img class="pic" src="../../../../assets/images/white/20@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 21">
          <img class="pic" src="../../../../assets/images/white/21@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 22">
          <img class="pic" src="../../../../assets/images/white/22@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 23">
          <img class="pic" src="../../../../assets/images/white/23@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 24">
          <img class="pic" src="../../../../assets/images/white/24@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 25">
          <img class="pic" src="../../../../assets/images/white/25@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 26">
          <img class="pic" src="../../../../assets/images/white/26@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 27">
          <img class="pic" src="../../../../assets/images/white/27@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 28">
          <img class="pic" src="../../../../assets/images/white/28@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 29">
          <img class="pic" src="../../../../assets/images/white/29@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 30">
          <img class="pic" src="../../../../assets/images/white/30@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 31">
          <img class="pic" src="../../../../assets/images/white/31@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 32">
          <img class="pic" src="../../../../assets/images/white/32@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 33">
          <img class="pic" src="../../../../assets/images/white/33@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 34">
          <img class="pic" src="../../../../assets/images/white/34@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 35">
          <img class="pic" src="../../../../assets/images/white/35@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 36">
          <img class="pic" src="../../../../assets/images/white/36@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 37">
          <img class="pic" src="../../../../assets/images/white/37@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 38">
          <img class="pic" src="../../../../assets/images/white/38@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 99">
          <img class="pic" src="../../../../assets/images/white/99@1x.png" alt="" />
        </span>

        <span class="wendu_1"> {{ item.now.temperature }}℃</span>
      </li>
    </ol>
    <ol class="newcity" *ngFor="let item of shanghaicity">
      <li class="taiyuan">上海市</li>
      <li class="newcity_text">
        <span *ngIf="item.now.code == 0">
          <img class="pic" src="../../../../assets/images/white/0@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 1">
          <img class="pic" src="../../../../assets/images/white/1@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 2">
          <img class="pic" src="../../../../assets/images/white/2@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 3">
          <img class="pic" src="../../../../assets/images/white/3@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 4">
          <img class="pic" src="../../../../assets/images/white/4@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 5">
          <img class="pic" src="../../../../assets/images/white/5@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 6">
          <img class="pic" src="../../../../assets/images/white/6@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 7">
          <img class="pic" src="../../../../assets/images/white/7@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 8">
          <img class="pic" src="../../../../assets/images/white/8@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 9">
          <img class="pic" src="../../../../assets/images/white/9@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 10">
          <img class="pic" src="../../../../assets/images/white/10@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 11">
          <img class="pic" src="../../../../assets/images/white/11@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 12">
          <img class="pic" src="../../../../assets/images/white/12@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 13">
          <img class="pic" src="../../../../assets/images/white/13@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 14">
          <img class="pic" src="../../../../assets/images/white/14@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 15">
          <img class="pic" src="../../../../assets/images/white/15@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 16">
          <img class="pic" src="../../../../assets/images/white/16@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 17">
          <img class="pic" src="../../../../assets/images/white/17@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 18">
          <img class="pic" src="../../../../assets/images/white/18@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 19">
          <img class="pic" src="../../../../assets/images/white/19@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 20">
          <img class="pic" src="../../../../assets/images/white/20@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 21">
          <img class="pic" src="../../../../assets/images/white/21@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 22">
          <img class="pic" src="../../../../assets/images/white/22@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 23">
          <img class="pic" src="../../../../assets/images/white/23@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 24">
          <img class="pic" src="../../../../assets/images/white/24@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 25">
          <img class="pic" src="../../../../assets/images/white/25@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 26">
          <img class="pic" src="../../../../assets/images/white/26@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 27">
          <img class="pic" src="../../../../assets/images/white/27@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 28">
          <img class="pic" src="../../../../assets/images/white/28@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 29">
          <img class="pic" src="../../../../assets/images/white/29@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 30">
          <img class="pic" src="../../../../assets/images/white/30@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 31">
          <img class="pic" src="../../../../assets/images/white/31@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 32">
          <img class="pic" src="../../../../assets/images/white/32@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 33">
          <img class="pic" src="../../../../assets/images/white/33@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 34">
          <img class="pic" src="../../../../assets/images/white/34@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 35">
          <img class="pic" src="../../../../assets/images/white/35@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 36">
          <img class="pic" src="../../../../assets/images/white/36@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 37">
          <img class="pic" src="../../../../assets/images/white/37@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 38">
          <img class="pic" src="../../../../assets/images/white/38@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 99">
          <img class="pic" src="../../../../assets/images/white/99@1x.png" alt="" />
        </span>
        <span class="wendu_1"> {{ item.now.temperature }}℃</span>
      </li>
    </ol>
    <ol class="newcity" *ngFor="let item of shenyangcity">
      <li class="taiyuan">沈阳市</li>
      <li class="newcity_text">
        <span *ngIf="item.now.code == 0">
          <img class="pic" src="../../../../assets/images/white/0@2x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 1">
          <img class="pic" src="../../../../assets/images/white/1@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 2">
          <img class="pic" src="../../../../assets/images/white/2@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 3">
          <img class="pic" src="../../../../assets/images/white/3@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 4">
          <img class="pic" src="../../../../assets/images/white/4@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 5">
          <img class="pic" src="../../../../assets/images/white/5@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 6">
          <img class="pic" src="../../../../assets/images/white/6@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 7">
          <img class="pic" src="../../../../assets/images/white/7@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 8">
          <img class="pic" src="../../../../assets/images/white/8@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 9">
          <img class="pic" src="../../../../assets/images/white/9@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 10">
          <img class="pic" src="../../../../assets/images/white/10@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 11">
          <img class="pic" src="../../../../assets/images/white/11@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 12">
          <img class="pic" src="../../../../assets/images/white/12@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 13">
          <img class="pic" src="../../../../assets/images/white/13@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 14">
          <img class="pic" src="../../../../assets/images/white/14@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 15">
          <img class="pic" src="../../../../assets/images/white/15@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 16">
          <img class="pic" src="../../../../assets/images/white/16@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 17">
          <img class="pic" src="../../../../assets/images/white/17@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 18">
          <img class="pic" src="../../../../assets/images/white/18@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 19">
          <img class="pic" src="../../../../assets/images/white/19@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 20">
          <img class="pic" src="../../../../assets/images/white/20@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 21">
          <img class="pic" src="../../../../assets/images/white/21@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 22">
          <img class="pic" src="../../../../assets/images/white/22@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 23">
          <img class="pic" src="../../../../assets/images/white/23@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 24">
          <img class="pic" src="../../../../assets/images/white/24@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 25">
          <img class="pic" src="../../../../assets/images/white/25@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 26">
          <img class="pic" src="../../../../assets/images/white/26@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 27">
          <img class="pic" src="../../../../assets/images/white/27@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 28">
          <img class="pic" src="../../../../assets/images/white/28@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 29">
          <img class="pic" src="../../../../assets/images/white/29@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 30">
          <img class="pic" src="../../../../assets/images/white/30@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 31">
          <img class="pic" src="../../../../assets/images/white/31@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 32">
          <img class="pic" src="../../../../assets/images/white/32@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 33">
          <img class="pic" src="../../../../assets/images/white/33@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 34">
          <img class="pic" src="../../../../assets/images/white/34@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 35">
          <img class="pic" src="../../../../assets/images/white/35@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 36">
          <img class="pic" src="../../../../assets/images/white/36@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 37">
          <img class="pic" src="../../../../assets/images/white/37@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 38">
          <img class="pic" src="../../../../assets/images/white/38@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 99">
          <img class="pic" src="../../../../assets/images/white/99@1x.png" alt="" />
        </span>
        <span class="wendu_1"> {{ item.now.temperature }}℃</span>
      </li>
    </ol>
    <ol class="newcity" *ngFor="let item of xiancity">
      <li class="taiyuan">西安市</li>
      <li class="newcity_text">
        <span *ngIf="item.now.code == 0">
          <img class="pic" src="../../../../assets/images/white/0@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 1">
          <img class="pic" src="../../../../assets/images/white/1@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 2">
          <img class="pic" src="../../../../assets/images/white/2@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 3">
          <img class="pic" src="../../../../assets/images/white/3@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 4">
          <img class="pic" src="../../../../assets/images/white/4@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 5">
          <img class="pic" src="../../../../assets/images/white/5@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 6">
          <img class="pic" src="../../../../assets/images/white/6@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 7">
          <img class="pic" src="../../../../assets/images/white/7@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 8">
          <img class="pic" src="../../../../assets/images/white/8@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 9">
          <img class="pic" src="../../../../assets/images/white/9@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 10">
          <img class="pic" src="../../../../assets/images/white/10@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 11">
          <img class="pic" src="../../../../assets/images/white/11@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 12">
          <img class="pic" src="../../../../assets/images/white/12@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 13">
          <img class="pic" src="../../../../assets/images/white/13@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 14">
          <img class="pic" src="../../../../assets/images/white/14@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 15">
          <img class="pic" src="../../../../assets/images/white/15@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 16">
          <img class="pic" src="../../../../assets/images/white/16@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 17">
          <img class="pic" src="../../../../assets/images/white/17@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 18">
          <img class="pic" src="../../../../assets/images/white/18@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 19">
          <img class="pic" src="../../../../assets/images/white/19@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 20">
          <img class="pic" src="../../../../assets/images/white/20@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 21">
          <img class="pic" src="../../../../assets/images/white/21@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 22">
          <img class="pic" src="../../../../assets/images/white/22@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 23">
          <img class="pic" src="../../../../assets/images/white/23@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 24">
          <img class="pic" src="../../../../assets/images/white/24@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 25">
          <img class="pic" src="../../../../assets/images/white/25@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 26">
          <img class="pic" src="../../../../assets/images/white/26@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 27">
          <img class="pic" src="../../../../assets/images/white/27@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 28">
          <img class="pic" src="../../../../assets/images/white/28@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 29">
          <img class="pic" src="../../../../assets/images/white/29@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 30">
          <img class="pic" src="../../../../assets/images/white/30@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 31">
          <img class="pic" src="../../../../assets/images/white/31@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 32">
          <img class="pic" src="../../../../assets/images/white/32@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 33">
          <img class="pic" src="../../../../assets/images/white/33@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 34">
          <img class="pic" src="../../../../assets/images/white/34@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 35">
          <img class="pic" src="../../../../assets/images/white/35@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 36">
          <img class="pic" src="../../../../assets/images/white/36@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 37">
          <img class="pic" src="../../../../assets/images/white/37@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 38">
          <img class="pic" src="../../../../assets/images/white/38@1x.png" alt="" />
        </span>
        <span *ngIf="item.now.code == 99">
          <img class="pic" src="../../../../assets/images/white/99@1x.png" alt="" />
        </span>
        <span class="wendu_1"> {{ item.now.temperature }}℃</span>
      </li>
    </ol>
  </div>
</div>
