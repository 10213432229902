import { Component, OnInit, AfterViewInit } from '@angular/core'

@Component({
  selector: 'app-standard',
  templateUrl: './standard.component.html',
  styleUrls: ['./standard.component.scss'],
})
export class StandardComponent implements OnInit {
  totals = 41.5
  standcolor: string
  standlist = [
    {
      name: '今日',
      total: '41.5',
    },
    {
      name: '本周',
      total: '63.3',
    },
    {
      name: '本月',
      total: '66.1',
    },
    {
      name: '全年',
      total: '67',
    },
  ]
  constructor() {}

  standOption: any

  initstandard() {
    const handred: number = 100
    var point: number = this.totals

    this.standOption = {
      title: {
        text: point + '%',
        x: 'center',
        y: 'center',
        textStyle: {
          fontWeight: 'normal',
          color: '#66fefe',
          fontSize: '12',
        },
      },

      tooltip: {
        formatter: function(params) {
          return params.name + '：' + params.percent + ' %'
        },
      },

      series: [
        {
          name: 'circle',
          type: 'pie',
          clockWise: true,
          radius: ['50%', '66%'],
          itemStyle: {
            normal: {
              label: {
                show: false,
              },
              labelLine: {
                show: false,
              },
            },
          },
          hoverAnimation: false,
          data: [
            {
              value: point,
              name: '已节能',
              itemStyle: {
                normal: {
                  color: {
                    // 颜色渐变
                    colorStops: [
                      {
                        offset: 0,
                        color: '#4FADFD', // 0% 处的颜色
                      },
                      {
                        offset: 1,
                        color: '#28E8FA', // 100% 处的颜色1
                      },
                    ],
                  },
                  label: {
                    show: false,
                  },
                  labelLine: {
                    show: false,
                  },
                },
              },
            },
            {
              name: '未节能',
              value: handred - point,
              itemStyle: {
                normal: {
                  color: '#E1E8EE',
                },
              },
            },
          ],
        },
      ],
    }
  }
  getstand(name) {
    console.log(name, 'name')
    this.standlist.map((val, index) => {
      if (val.name == name) {
        this.standcolor = '#DC5F3D'
        this.totals = Number(val.total)
      }
    })
    this.initstandard()
  }
  ngOnInit(): void {
    this.initstandard()
  }
}
