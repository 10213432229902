<nz-layout>
  <nz-content>
    <div
      class="authContainer"
      [ngClass]="{
        ui__layout__squaredBorders: isSquaredBorders,
        ui__layout__cardsShadow: isCardShadow,
        ui__layout__borderless: isBorderless,
        white: authPagesColor === 'white',
        gray: authPagesColor === 'gray'
      }"
      [ngStyle]="{
        backgroundImage: authPagesColor === 'image' ? 'url(assets/images/content/photos/7.jpg)' : ''
      }"
    >
      <div class="containerInner">
        <div
          [@slideFadeinUp]="routeAnimation(outlet, 'slideFadeinUp')"
          [@slideFadeinRight]="routeAnimation(outlet, 'slideFadeinRight')"
          [@zoomFadein]="routeAnimation(outlet, 'zoomFadein')"
          [@fadein]="routeAnimation(outlet, 'fadein')"
        >
          <router-outlet #outlet="outlet"></router-outlet>
        </div>
      </div>
      <div class="mt-auto pb-5 pt-5">
        <div class="text-center">
          Copyright © 2009-2020 瑞海热控
        </div>
      </div>
    </div>
  </nz-content>
</nz-layout>
